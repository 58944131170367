.admin {
    width: 100%;
    height: 560px;
}

.user {
    width: 100%;
    height: 250px;
}

@media screen and (max-height: 860x) {
    .admin {
        height: 510px;
    }
}

@media screen and (max-height: 810px) {
    .admin {
        height: 460px;
    }
}

@media screen and (max-height: 760px) {
    .admin {
        height: 410px;
    }
}

@media screen and (max-height: 710px) {
    .admin {
        height: 360px;
    }
}