@import '../../../assets/styles/variables.module.scss';

.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
    }
}

.high_zindex {
    position: absolute;
    top:50;
    z-index: 20000;
}

.buttons {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem
}

.cancel {
    font-size: 1.4rem;
    color: $red-primary;
    font-weight: 400;
    cursor: pointer;
}

.customButton {
    width: 157px;
}
