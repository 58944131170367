@import "../../../assets/styles/variables.module.scss";

.main_middle {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  position: relative;
}

.middle_item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.middle_item_label {
  font-size: 1.6rem;
  font-weight: 400;
}

.middle_item_input {
  display: flex;
  gap: 1rem;
  background-color: $input_background;
  padding: 1.5rem 1rem;
  width: 100%;
  position: relative;

  & input {
    width: 100%;
    font-size: 1.6rem;
    border-style: none;
    outline: none;
    background-color: $input_background;
    color: $gray-primary;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: 1.5rem;
}

.summary_item {
  display: flex;
  gap: 1rem;

  & span:nth-child(2) {
    font-weight: bolder;
  }
}

.buttons {
  // position: absolute;
  // bottom: -22.5rem;
  // left: 0;
  // right: 0;
  // margin-right: auto;
  // margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.cancel {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}
