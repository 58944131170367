@import '../../../assets/styles/variables.module.scss';

.main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }

}

.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
        text-align: center;

    }
}