@import '../../../assets/styles/variables.module.scss';

.container {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}

.default {
    background-color: $input_background;
}

.green {
    background-color: $green-primary;
    color: white;
}

.red {
    background-color: $red-primary;
    color: white;
}