@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 2rem 2rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_title {
    @include page-title
}

.main_buttons {
    display: flex;
    gap: 1rem;
    width: 800px;
    justify-content: flex-end;
}

.main_middle {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    @include gray-bottom-border;
}

.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    font-size: 1.4rem;
    font-weight: 500;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(3) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}

.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}