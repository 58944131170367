@import '../../assets/styles/variables.module.scss';

.main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }

}

.main_middle {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.middle_item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.middle_item_label {
    font-size: 1.6rem;
    font-weight: 400;
}

.middle_item_input {
    display: flex;
    gap: 1rem;
    background-color: $input_background;
    width: 100%;
    position: relative;

    & input {
        width: 100%;
        font-size: 1.4rem;
        border-style: none;
        outline: none;
        background-color: $input_background;
        color: $gray-primary;
    }


}

.selector {
    padding: 0.5rem 1rem;
}

.custominput {
    position: relative;

    &_exit {
        position: absolute;
        top: 1.20rem;
        left: 0.5rem;
        cursor: pointer;
    }
    
}

.buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
}

.cancel {
    font-size: 1.4rem;
    color: $red-primary;
    font-weight: 400;
    cursor: pointer;
}

.customButton {
    width: 157px;
}
