@import '../../../assets/styles/variables.module.scss';

.container {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.red {
    background-color: $red-primary;
    
}

.blue {
    background-color: $blue-primary;
}

.green {
    background-color: $green-primary;
}