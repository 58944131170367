@import '../../../assets/styles/variables.module.scss';

.website {
    text-transform: none !important;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.5rem;

    &:hover {
        color: $blue-primary;

        & div {
            visibility: visible !important;
        }
    }

    & a {
        text-decoration: none;
        color: $blue-primary
    }

}

.website_top {
    display: flex;
    justify-content: space-between;
}

.icons {
    display: flex;
    gap: 0.5rem;
    visibility: hidden;

    & img {
        cursor: pointer;
    }
}


.website_text {
    color: $blue-primary;
    font-weight: 500;
}

.portal_text {
    color: orange;
    font-weight: 500;
}

.note {
    padding-top: 0.5rem;
    border-top-style: solid;
    border-color: $blue-primary;
    text-align: right;
    color: $red-primary;
}