@import '../../assets/styles/variables.module.scss';

.main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }
}

.data {
    height: 200px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1rem;
    position: relative;
}

.item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 100px;
    column-gap: 2rem;
    align-items: center;
    font-size: 1.6rem;
}

.logo {
    cursor: pointer;
    max-width: 150px;
}

.domain {
   align-self: center;
   text-align: center;
}

.buttons {
    justify-self: end;
    display: flex;
    gap: 1.5rem;

}

.button {
    cursor: pointer;
}

//////////////////////////////////////////

.add_new_website {
    position: absolute;
    top: 31rem;
    margin-top: 2rem;
    margin-left: 23.5rem;
    border-style: solid;
    border-width: 1px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.add_new_exit {
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
}

.add_new_title {
    font-size: 1.6rem;
    text-align: center;
}

.add_new_main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}
