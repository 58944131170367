@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container {
    @include page-container-default
}

.main {
    width: 100%;
    margin-bottom: 2rem;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 2rem 2rem;
}

.profile {
    height: 225px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 4rem;
    align-items: center;
}

.profile_picture {
    margin-left: 2rem;
    display: flex;
    grid-column: 1/2;
    grid-row: 1/3;
    width: 150px;
    height: 150px;
    border-radius: 50%;

    & img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
}

.info {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: end;
}

.info_item {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $blue-primary;
    font-size: 1.6rem;
    font-weight: 400;

    & img {
        display: flex;
        height: 30px;
        width: 30px;
        padding: 0.25rem 0.25rem;
        border-style: solid;
        border-width: 2px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.buttons {
    margin-top: 2rem;
    align-self: baseline;
    display: flex;
    gap: 1rem;
}

.button {
    min-width: 153px;
}

@media screen and (max-width: 1000px) {

    .profile {
        height: unset;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .info {
        align-self: center;
    }

    .buttons {
        align-self: center;
        margin-top: unset;
    }

}

@media screen and (max-width: 500px) {
    .buttons {
        align-self: unset;
        flex-direction: column;
        align-items: end;

    }

    .button {
        min-width: unset;
    }

}

@media screen and (max-width: 400px) {

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}