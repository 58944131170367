@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default
}

.general_info_container {
    height: 60px;
    width: 100%;
    background-color: rgb(219,223,231);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-left: 2rem;
    cursor: pointer;

    & span {
        color: $blue-primary;
        font-size: 1.6rem;
        font-weight: 400;
    }

    & img {
        display: flex;  
    }

}

.arrowdown {
    transform: translateX(-0.5rem);
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 2rem;
    position: relative;
    margin-bottom: 2rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_title {
    @include page-title
}

.main_buttons {
    display: flex;
    gap: 2rem;
    width:300px;
    justify-content: flex-end;
}

.onairbutton {
    width: 150px;
}

.main_middle {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    @include gray-bottom-border;
}


.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;

    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(3) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}

.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
