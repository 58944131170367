@import '../../assets/styles/variables.module.scss';

.container {
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
}

.main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }
}

.main_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.select {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 210px
}

.buttons {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4rem
}

.cancel {
    font-size: 1.4rem;
    color: $red-primary;
    font-weight: 400;
    cursor: pointer;
}

.customButton {
    width: 157px;
}
// .main {
//     margin-top: 2rem;
//     display: flex;
//     flex-direction: column;
//     row-gap: 4rem;
// }

// .main_top {
//     align-self: center;
//     display: flex;
//     gap: 1rem
// }

// .input {
//     width: 210px;
//     display: flex;
//     flex-direction: column;
//     gap: 0.5rem;

//     & label {
//         font-size: 1.3rem;
//     }

// }

// .buttons {
//     display: flex;
//     justify-content: center;
//     gap: 2rem;
// }
