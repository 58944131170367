.main {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    height: 300px;
    overflow-y: auto;
}

.refuseitem {
    font-size: 2rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;

    &_title {
        font-weight: 500;
        color: red;
        width: 140px;
        text-align: right;
    }

    &_paragraph {
        text-align: right;
        max-width: 500px;
    }
}