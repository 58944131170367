@import '../../assets/styles/variables.module.scss';

.container {
    height: 100px;
    width: 20vw;
    max-width: 360px;
    min-width: 300px;
    background-color: white;
    display: flex;
    gap: 2rem;
    padding: 2rem 3rem;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.details_container {
    text-align: left;
}

.title {
    font-size: 1.6rem;
    text-transform: capitalize;

}

.value {
    font-size: 2rem;
    font-weight: bold;
    color: $blue-primary
}

@media screen and (max-width: 930px) {

    .container {
        max-width: unset;
        min-width: unset;
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .title {
        font-size: 1.4rem;
    }

    .value {
        font-size: 1.6rem;
    }
}