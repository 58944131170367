@import '../../assets/styles/variables.module.scss';

.container {
    background-color: $background-color-all-pages;
    padding: 2rem 2rem;
    overflow-y: auto;
}

.main {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    overflow-y: auto;
}