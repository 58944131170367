@import "../../../assets/styles/variables.module.scss";

.main {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.main_item {
  display: flex;
  font-size: 1.6rem;
  gap: 2rem;
  align-items: center;
  color: red;
}

.main_title {
  font-size: 2rem;
}
