@import './variables.module.scss';

@mixin gray-bottom-border {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 2rem;
    border-bottom-color: $horizontal-line-color;
}

@mixin page-container-default {
    background-color: $background-color-all-pages;
    padding-top: $padding-top-all-pages;
    padding-right: 3rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;
}

@mixin page-title {
    color: $blue-primary;
    font-weight: 600;
    font-size: 1.8rem;
    width: 150px;
}

@mixin table-header {
    color: black;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1rem;
    min-width: 120px;
}

@mixin table-default {
    margin-top: 1rem;
    width: 100%;
    font-size: 1.6rem;
    text-transform: capitalize;
    text-align: center;
   
    & thead th {
        position: sticky;
        top: 0;
        background-color: $input_background;
    }

    & th {
        @include table-header;
    }

}