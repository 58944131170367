@import '../../assets/styles/variables.module.scss';

.container {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/people_background.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main {
    width: 440px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.top_container {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
}

.logo {
    width: 234px;

    img {
        width: 100%;
    }
}

.title {
    font-size: 2rem;
    font-weight: 500;
}

.form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & label {
        font-size: 1.6rem;
    }
}

.form_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.forgotpassword {
    color: $red-primary;
    font-size: 1.6rem;
    text-decoration: underline;
}

.signup {
    margin-top: 4rem;
}

@media screen and (max-width: 500px) {

    .container {
        background-image: unset;
    }

    .main {
        width: 100vw;
    }
}

.blockedUserMessage{
    font-size: 1.8rem;
    padding: 1rem;
    text-align: right;
}

.blocked_help {
    color: $red-primary;
    font-size: 1.6rem;
    text-decoration: underline;
}

.blocked_help_container{
    text-align: center;
}


@media (max-width: 768px){
    .locks_container{
        padding: 1em!important;
        font-size: 1em !important;
     }
}
