@import '../../assets/styles/variables.module.scss';

.container {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: $background-color-all-pages;
    color: $gray-primary;
    width: 206px;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    & input {
        border-style: none;
        outline: none;
        background-color: $background-color-all-pages;
        color: $gray-primary;
        font-size: 1.4rem;
        width: 150px;
    }
}

@media screen and (max-width: 385px) {
    .container {
        width: 180px;

        & input {
            width: 130px;
            font-size: 1.2rem;
        }
    }
}