@import "../../assets/styles/variables.module.scss";
@import "../../assets//styles/mixins.module.scss";

.container {
  @include page-container-default;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem 2rem;
  position: relative;
  margin-bottom: 2rem;
}

.main_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include gray-bottom-border;
}

.main_title {
  @include page-title;
}

.legend {
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow: auto;
  margin-right: 2rem;

  & span {
    font-size: 1.4rem;
    padding: 1rem 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.main_buttons {
  display: flex;
  gap: 1rem;
  width: 300px;
  justify-content: flex-end;
}

.main_middle {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  @include gray-bottom-border;
  margin-bottom: 1rem;
}

.table_data {
  & tr:hover {
    background-color: #e4e1e1;
  }

  & td {
    padding: 1.5rem 2rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
  }
}

.main_bottom {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
