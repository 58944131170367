@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 2rem;
    position: relative;
    margin-bottom: 2rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_title {
    @include page-title
}

.main_buttons {
    display: flex;
    gap: 1rem;
    width:300px;
    justify-content: flex-end;
}

.total {
    display: flex;
    align-items: center;
    font-size: 2rem;
    text-align: right;
}

.goback {
    cursor: pointer;
}

.main_middle {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    @include gray-bottom-border;
    margin-bottom: 1rem;
}


.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;

    }
}

.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile_pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}


@media screen and (max-width: 500px) {
    .container {
        width: 100vw;
    }

    .main_middle {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .main_buttons {
        gap: 0.5rem;
    }
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        height: 120vh;
    }
}
