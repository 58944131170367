@import '../../assets/styles/variables.module.scss';

.container {
    height: $top-components-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding: 0rem 2rem;
    color: #777;
}

.title {
    font-size: 2rem;
    text-transform: capitalize;
}

.right_side {
    display: flex;
    font-size: 1.6rem;
    gap: 1.5rem;
}

.convertion_container {
    margin-right: 3rem;
}

.convertion {
    color: $blue-primary;
    font-weight: bold;
    font-size: 1.8rem;
}

.convertion_date {
    font-size: 1.4rem;
}

.language_container {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
}

.language_icon {
    width: 100%;
    height: 100%;

    & img {
        width: 20px;
    }
}

.logout {
    cursor: pointer;
}