@import "../../assets/styles/variables.module.scss";

.main_middle {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.middle_item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.middle_item_label {
  font-size: 1.6rem;
  font-weight: 400;
}

.middle_item_input {
  display: flex;
  gap: 1rem;
  background-color: $input_background;
  padding: 1.5rem 1rem;
  width: 100%;
  position: relative;

  & input {
    width: 100%;
    font-size: 1.6rem;
    border-style: none;
    outline: none;
    background-color: $input_background;
    color: $gray-primary;
  }

  & span {
    position: absolute;
    background-color: $input_background;
    font-size: 1.6rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.remove_padding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
}

.cancel {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}

.customButton {
  width: 157px;
}
