@import '../../../assets/styles/variables.module.scss';

.portal_units {
    display: flex;
    justify-content: center;
    color: $blue-primary;

    & span {
        border-style: solid;
        border-width: 1px;
        padding: 0.5rem 1.25rem;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}


.portal_link {
    text-transform: none;
}