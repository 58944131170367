@import '../../assets/styles/variables.module.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 119px;
    width: 100vw;
}

.top {
    height: 50%;
    background-color: $color-foreshop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.image_container {
    width: 139px;
    display: flex;
    & img {
        width: 100%;
    }
}

.top_buttons {
    display: flex;
    gap: 0.5rem
}

.bottom {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.navbar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    & span {
        font-size: 1.3rem;
    }

}


.convertion {
    color: $blue-primary;
    font-weight: bold;
    font-size: 1.8rem;
}

.convertion_date {
    font-size: 1.4rem;
    color: $gray-primary;
}