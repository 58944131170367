@import '../../../assets/styles/variables.module.scss';

.domain {
    text-transform: none;
}

.count {
    text-align: center;
}

.paid {
    color: $green-primary
}

.notpaid {
    color: $red-primary
}