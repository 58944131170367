@import '../../assets/styles/variables.module.scss';

.container {
    background-color: $background-color-all-pages;
    padding-top: $padding-top-all-pages;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    overflow-x: hidden;

}

// The Padding and Margins values are not equal due to hidding scroll-bar with transform: translateX

.top_container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 3rem;
    padding-right: 2rem;
    padding-left: 3rem;
}

.charts_container {
    width: 100%;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translateX(25px);
    margin-right: 20px;
    padding-right: 3.5rem;
    margin-bottom: 2rem;
 
}

.chart {
    padding: 2rem 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.chart_title {
    font-size: 1.8rem;
    font-weight: 600;
}



@media screen and (max-width: 1380px) {

    .top_container {
        justify-content: center;
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 930px) {

    .top_container {
        gap: 1rem;
    }

}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .charts_container {
        overflow-y: hidden;
    }
}
